import gitHubFinder from "../images/portfolio/githu-finder_green.mp4"
import devApp from '../images/portfolio/developers_degradat.jpg'
import phoneApi from '../images/portfolio/phone-api.jpg'
import ornamante from '../images/portfolio/ornamante.jpg'
import linerGesbite from '../images/portfolio/liner-gesbite.jpg'
import fontEspaiCreatiu from '../images/portfolio/font-espai-creatiu.jpg'
import spotifav from '../images/portfolio/spotifav.jpg'

const projects = [
    {
        name: 'Spotifav',
        desc: 'Full stack application that uses the Spotify API to display featured categories. You must first log in with your Spotify user to receive the token for the requests. Done with React, Context, JSX and Hooks.',
        type: 'img',
        img: spotifav,
        url: 'https://ohilari-spotify-app.netlify.app/'
    },
    {
        name: 'GitHub Finder 🕵🏻‍♂️',
        desc: 'This App is a cool GitHub Finder, the intention was to create a GitHub simulator to find users and search their repositories using their API. It shows info about users and their repos! Done with React.',
        type: 'mp4',
        img: gitHubFinder,
        url: 'https://hilaring.github.io/github-simulator/'
    },
    {
        name: 'Smartphone Catalogue 📱',
        desc: 'Full Stack application to manage the information of a catalogue of Smartphones. The back end uses an API built with Node.js and Express.js to interact with Mongo DB and Mongoose. The front has been created with React and Hooks.',
        type: 'img',
        img: phoneApi,
        url: 'https://ohilari-phone-app.netlify.app/'
    },
    {
        name: 'DEV Manappger',
        desc: "Web application to simulate user management. Done with React and Hooks, this app loads sample user content from the randomusers.me API and saves them to your browser's local storage for you to create, edit or delete users. ",
        type: 'img',
        img: devApp,
        url: '',
        url2: 'https://hilaring.github.io/app-developer-manager/'
    },
    {
        name: 'Ecommerce',
        desc: 'Online store done for Ornamante using Wordpress, WooCommerce, JS, PHP, HTML5 & CSS3. This ecommerce contains a cache system implemented for improve performance optimization and user experience.',
        type: 'img',
        img: ornamante,
        url: 'https://ornamante.com'
    },
    {
        name: 'Landing page',
        desc: 'A landing page created for an interior design company. This page was created for a customer advertising campaign with a contact form to count conversions. Done with Bootstrap 4, HTML5, CSS3, Javascript & jQuery. ',
        type: 'img',
        img: fontEspaiCreatiu,
        url: 'https://fontespaicreatiu.com'
    },
    {
        name: 'Landing page',
        desc: 'Landing page for a client dedicated to modular and prefabricated construction with the intention of attracting new potential clients and improving their main image. Done with Bootstrap 4, HTML5, CSS3, Javascript & jQuery.',
        type: 'img',
        img: linerGesbite,
        url: 'https://www.finquesferro.com/construction/'
    }
]

export default projects;