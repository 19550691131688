import React from "react"
import Layout from "./Layout"
import SEO from "./Seo"
import PageTransition from 'gatsby-v2-plugin-page-transitions';
import projects from '../helpers/projects'

// const IndexPage = ({

//   data: {
//     allMarkdownRemark: { edges },
//   },
// }) => {
//   const Portfolio = edges
//     .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
//     .map(edge => <ProjectLink key={edge.node.id} post={edge.node} />)

const IndexPage = () => (
  <Layout>
    <SEO
      title={'My portfolio'}
      description={'Here you can see some of my projects since I started as a professional programmer. 👉 Visit it now!'}
    />
    <PageTransition>
      <div
        style={{
          margin: `0 auto`,
          minHeight: `70vh`
        }}
      >
        <h1>Portfolio</h1>
        <h3>Real and learning projects. Work, effort and dedication every day <span role="img" aria-label="Working hard">💪</span></h3>
        <p className="skills-p">I try to always be in training with projects and courses or reading books about good practices and clean code! Here you are some projects that I've been working on, I hope you like them, check them out!</p>
        <hr></hr>
        <div className="projects">
          {projects.map((project, index) => (
            <div key={index} className="project-card">
              {project.type === 'img'
                ?
                <img alt={project.name} src={project.img} loading="lazy" />
                :
                <video autoPlay loop>
                  <source src={project.img} type="video/mp4" />
                </video>
              }
              <h2>{project.name}</h2>
              <p>{project.desc}</p>
              {(project.url.length > 0) ?
                <a className="link-view" href={project.url} alt={project.name} target="_blank" rel="noreferrer">VIEW</a>
                :
                <a className="link-view" style={{ textDecoration: "line-through" }}>VIEW</a>
              }
            </div>
          ))}
        </div>
        {/* <div>
          {Portfolio}
        </div> */}
      </div>
    </PageTransition>
  </Layout>
)

export default IndexPage

// export const pageQuery = graphql`
//   query {
//     allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
//       edges {
//         node {
//           id
//           excerpt(pruneLength: 250)
//           frontmatter {
//             date(formatString: "MMMM DD, YYYY")
//             slug
//             title
//             description
//             featuredImage {
//               src 
//               alt
//             }
//           }
//         }
//       }
//     }
//   }
// `   